@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }
}

.Login{
  background-color: #eaf6eb;
}

.LoginBox {
  padding: 20px;
  border-radius: 0.5em;
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
}

.LoginBox .info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.LoginBox form {
  min-width: 350px;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
}

.btn {
  display: inline-block;
  background: #2bc87f;
  border: 1px solid darken(#1fbf3a, 5%);
  padding: 0.5em 2em;
  color: white;
  margin-right: 0.5em;
  box-shadow: inset 0px 1px 0px fadeout(white, 80%);
}

input[type="checkbox"]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}

.navbar-brand {
  width: 60px;
  height: 60px;
  border-radius: 60%;
  overflow: hidden;
  margin-top: -8px;
}

.password-icon {
  position: absolute;
  margin-right: 10px;
  align-self: center;
  z-index: 9999;
}
