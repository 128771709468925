
body {
  background:#ffffff;
  background: linear-gradient(to right, #ffffff, #ffffff);
  margin: 0rem;
  padding: 0rem;
}


[type="date"] {
  background:#fff url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)  97% 50% no-repeat ;
}
[type="date"]::-webkit-inner-spin-button {
  display: none;
}
[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}

/* custom styles */
body {
  background: #ffffff;
  font: 16px Geneva, 'Lucida Sans', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
  margin: 0;
  padding: 0;
}
label {
  display: block;
}
input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
  width: 190px;
}

.mt-80{
  margin-top: 80px;
}

.color-nav{
  background: #2bc87f;
}

.nav-link{
  font-family: 'Roboto', sans-serif;
  color: #f5f5f5 !important;
  font-size: 1.2em;
}

.nav-link:hover{
  color: #fcfcfcc4 !important;
}

.cmpLogo{
  object-fit: cover;
  color: #004727;
  font-size: large;
  font-weight: 500;
  filter: brightness(0.5) invert(0);
}

.btn-color{
  background: #2bc87f;
}

.heading{
  font-size: 1.5em;
  font-weight: 600;
}