.spinnerContainer {
    height: 100vh;
    width: 100vw;
    background: rgba(122, 122, 122, 0.8);
    position: absolute;
    z-index: 10000;
  }
  
  
  
  .spinnerContainer .spinnerBox{
    height: 20vh;
    width: 25vw;
    min-width: 200px;
    background: #fff;
    border-radius: 10px;
  }

  .spinnerBox .loadingTxt{
      margin-left: 0.5em;
      font-size: 1em;
      font-weight: 600;
  }